import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Grid,
} from '@material-ui/core';
import {
  CloudQueue as CloudIcon,
  Storage as StorageIcon,
  Public as PublicIcon,
  Widgets as WidgetsIcon,
  Language as LanguageIcon,
  Security as SecurityIcon,
  Close as CloseIcon,
  InsertDriveFile as JobIcon,
  Schedule as CronJobIcon,
} from '@material-ui/icons';
import { Doughnut } from 'react-chartjs-2';
import { useApi } from '@backstage/core-plugin-api';
import { k8sResourcesApiRef } from '../api/K8sResourcesApi';
import {
  ClusterRecord,
  K8sResources,
  Node,
  Pod,
  Deployment,
  Service,
  Ingress,
  Job,
  CronJob
} from '../types/k8s';
import { ContainerStatus } from './ContainerStatus';

// Import Chart.js and required components
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';

// Register the components
Chart.register(ArcElement, ChartTooltip, Legend);

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 'calc(100vh - 64px)',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  sidebar: {
    width: 280,
    borderLeft: `1px solid ${theme.palette.divider}`,
    flexShrink: 0,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    right: 0,
    top: 64,
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      width: '100%',
      borderLeft: 'none',
      borderTop: `1px solid ${theme.palette.divider}`,
      height: 'auto',
    },
  },
  mainArea: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(3),
    marginRight: 280,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  fullHeightFirstDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  resourceCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    transition: 'transform 0.2s, box-shadow 0.3s, background-color 0.3s',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[6],
      backgroundColor: theme.palette.action.hover,
    },
  },
  nodeCard: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    width: '100%',
    transition: 'transform 0.2s, box-shadow 0.3s',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[6],
    },
  },
  chartBox: {
    width: '100%',
    height: 250,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s, transform 0.3s',
    '&:hover': {
      boxShadow: theme.shadows[6],
      transform: 'scale(1.02)',
    },
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  tabContent: {
    marginTop: theme.spacing(3),
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  preformatted: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
    fontFamily: 'monospace',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
  },
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 300,
    '& th': {
      color: theme.palette.text.secondary,
    },
    '& td': {
      color: theme.palette.text.primary,
    },
    '& tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  formControl: {
    marginBottom: theme.spacing(3),
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  header: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  emptyState: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },
}));

// Resource Types Definition
const resourceTypes = [
  { key: 'overview', label: 'Overview', icon: <CloudIcon />, namespaceScoped: false },
  { key: 'pods', label: 'Pods', icon: <StorageIcon />, namespaceScoped: true },
  { key: 'deployments', label: 'Deployments', icon: <WidgetsIcon />, namespaceScoped: true },
  { key: 'services', label: 'Services', icon: <PublicIcon />, namespaceScoped: true },
  { key: 'secrets', label: 'Secrets', icon: <SecurityIcon />, namespaceScoped: true },
  { key: 'ingresses', label: 'Ingresses', icon: <LanguageIcon />, namespaceScoped: true },
  { key: 'jobs', label: 'Jobs', icon: <JobIcon />, namespaceScoped: true },
  { key: 'cronjobs', label: 'CronJobs', icon: <CronJobIcon />, namespaceScoped: true },
];

export const K8sResourcesWidget: React.FC = () => {
  const classes = useStyles();
  const k8sApi = useApi(k8sResourcesApiRef);

  // State Management with Proper Types
  const [clusters, setClusters] = useState<ClusterRecord[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<string>('');
  const [resourceData, setResourceData] = useState<K8sResources>({
    namespaces: [],
    nodes: [],
    pods: [],
    deployments: [],
    services: [],
    ingresses: [],
    jobs: [],
    cronjobs: [],
  });
  const [selectedResourceType, setSelectedResourceType] = useState<string>('overview');
  const [selectedNamespace, setSelectedNamespace] = useState<string>('');
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedPod, setSelectedPod] = useState<Pod | null>(null);
  const [selectedDeployment, setSelectedDeployment] = useState<Deployment | null>(null);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedIngress, setSelectedIngress] = useState<Ingress | null>(null);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedCronJob, setSelectedCronJob] = useState<CronJob | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch Clusters on Mount
  useEffect(() => {
    (async () => {
      try {
        const list: ClusterRecord[] = await k8sApi.listClusters();
        setClusters(list);
        if (list.length > 0) {
          setSelectedCluster(list[0].name);
        }
      } catch (err) {
        console.error('Error listing clusters', err);
        setError('Failed to load clusters.');
      }
    })();
  }, [k8sApi]);

  // Fetch Resource Data on Cluster or Namespace Change
  useEffect(() => {
    if (!selectedCluster) return;

    const fetchResources = async () => {
      setLoading(true);
      setError(null);
      try {
        const data: K8sResources = await k8sApi.getClusterResources(selectedCluster);
        setResourceData(
          data || {
            namespaces: [],
            nodes: [],
            pods: [],
            deployments: [],
            services: [],
            ingresses: [],
            jobs: [],
            cronjobs: [],
          }
        );
      } catch (err) {
        console.error('Error fetching cluster resources', err);
        setError('Failed to load cluster resources.');
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, [selectedCluster, k8sApi]);

  // Extract Namespaces
  const namespaces: string[] = resourceData.namespaces;

  // Filter Displayed Resources based on Resource Type and Namespace
  let displayedResources: any[] = [];
  if (selectedResourceType !== 'overview') {
    displayedResources = resourceData[selectedResourceType as keyof K8sResources] || [];
    const resDef = resourceTypes.find(r => r.key === selectedResourceType);
    if (resDef?.namespaceScoped && selectedNamespace) {
      displayedResources = displayedResources.filter(
        (r: any) => r.metadata?.namespace === selectedNamespace
      );
    }
  }

  // Handle Dialog Open/Close with Proper Types
  const handleOpenJobDialog = (job: Job) => {
    setSelectedJob(job);
  };
  const handleCloseJobDialog = () => {
    setSelectedJob(null);
  };
  const handleOpenCronJobDialog = (cronJob: CronJob) => {
    setSelectedCronJob(cronJob);
  };
  const handleCloseCronJobDialog = () => {
    setSelectedCronJob(null);
  };
  const handleOpenNodeDialog = (node: Node) => {
    setSelectedNode(node);
    setTabIndex(0);
  };
  const handleCloseNodeDialog = () => {
    setSelectedNode(null);
  };
  const handleOpenPodDialog = (pod: Pod) => {
    setSelectedPod(pod);
  };
  const handleClosePodDialog = () => {
    setSelectedPod(null);
  };
  const handleOpenDeploymentDialog = (deployment: Deployment) => {
    setSelectedDeployment(deployment);
  };
  const handleCloseDeploymentDialog = () => {
    setSelectedDeployment(null);
  };
  const handleOpenServiceDialog = (service: Service) => {
    setSelectedService(service);
  };
  const handleCloseServiceDialog = () => {
    setSelectedService(null);
  };
  const handleOpenIngressDialog = (ingress: Ingress) => {
    setSelectedIngress(ingress);
  };
  const handleCloseIngressDialog = () => {
    setSelectedIngress(null);
  };

  // Prepare Data for Doughnut Charts
  const prepareChartData = () => {
    const runningPods = resourceData.pods.filter(pod => pod.status.phase === 'Running').length;
    const totalPods = resourceData.pods.length;

    const runningDeployments = resourceData.deployments.filter(
      depl => depl.status.availableReplicas > 0
    ).length;
    const totalDeployments = resourceData.deployments.length;

    return {
      pods: { running: runningPods, total: totalPods },
      deployments: { running: runningDeployments, total: totalDeployments },
    };
  };

  const chartData = prepareChartData();

  // Render Helpers
  const renderCapacity = (capacity: Record<string, string>) => (
    <Box mb={2}>
      <Typography variant="subtitle1">
        <strong>Capacity:</strong>
      </Typography>
      <TableContainer component={Card} elevation={0} className={classes.tableContainer}>
        <Table size="small" className={classes.table}>
          <TableBody>
            {Object.entries(capacity).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row" style={{ textTransform: 'capitalize' }}>
                  {key.replace(/-/g, ' ')}
                </TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderLabels = (labels?: Record<string, string> | null) => (
    <Box mb={2}>
      <Typography variant="subtitle1">
        <strong>Labels:</strong>
      </Typography>
      {(!labels || Object.keys(labels).length === 0) ? (
        <Typography variant="body2">No labels available.</Typography>
      ) : (
        <List dense>
          {Object.entries(labels).map(([key, value]) => (
            <React.Fragment key={key}>
              <ListItem>
                <ListItemText primary={key.replace(/-/g, ' ')} secondary={value} />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
      )}
    </Box>
  );

  // Node Details
  const renderNodeDialogContent = () => {
    if (!selectedNode) return null;

    const { metadata, status } = selectedNode;
    const nodeInfo = status.nodeInfo;

    // Extract Addresses
    const internalIP =
      status.addresses.find(addr => addr.type === 'InternalIP')?.address || 'N/A';
    const hostname =
      status.addresses.find(addr => addr.type === 'Hostname')?.address || 'N/A';

    return (
      <Box>
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => setTabIndex(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="General Info" />
          <Tab label="Pods" />
        </Tabs>
        <Divider />
        <Box className={classes.tabContent}>
          {tabIndex === 0 && (
            <>
              <Typography variant="subtitle1">
                <strong>Hostname:</strong> {hostname}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Internal IP:</strong> {internalIP}
              </Typography>
              <Typography variant="subtitle1">
                <strong>OS Image:</strong> {nodeInfo.osImage}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Kernel Version:</strong> {nodeInfo.kernelVersion}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Kubelet Version:</strong> {nodeInfo.kubeletVersion}
              </Typography>
              {renderCapacity(status.capacity)}
              {renderLabels(metadata.labels)}
            </>
          )}
          {tabIndex === 1 && (
            <List>
              {resourceData.pods
                .filter((pod: Pod) => pod.spec.nodeName === metadata.name)
                .map((pod: Pod) => (
                  <ListItem
                    button
                    key={pod.metadata.name}
                    onClick={() => handleOpenPodDialog(pod)}
                    className={classes.listItem}
                  >
                    <ListItemText
                      primary={pod.metadata.name}
                      secondary={`Status: ${pod.status.phase}`}
                    />
                  </ListItem>
                ))}
            </List>
          )}
        </Box>
      </Box>
    );
  };

  // Pod Details
  const renderPodDialogContent = () => {
    if (!selectedPod) return null;

    const { metadata, status, spec } = selectedPod;

    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          General Info
        </Typography>
        <Typography>
          <strong>Name:</strong> {metadata.name}
        </Typography>
        <Typography>
          <strong>Namespace:</strong> {metadata.namespace}
        </Typography>
        {renderLabels(metadata.labels)}
        {renderLabels(metadata.annotations)}
        <Typography>
          <strong>Status:</strong> {status.phase}
        </Typography>
        <Typography>
          <strong>Pod IP:</strong> {status.podIP}
        </Typography>
        <Typography>
          <strong>QoS Class:</strong> {status.qosClass}
        </Typography>
        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Containers
        </Typography>
        {spec.containers.map((container: any, idx: number) => (
          <Box key={idx} mb={2}>
            <Typography>
              <strong>Name:</strong> {container.name}
            </Typography>
            <Typography>
              <strong>Image:</strong> {container.image}
            </Typography>
            <Typography>
              <strong>Ports:</strong>{' '}
              {container.ports ? container.ports.map((p: any) => p.containerPort).join(', ') : 'N/A'}
            </Typography>
            <Typography>
              <strong>Liveness Probe:</strong>
            </Typography>
            <Box className={classes.preformatted}>
              <Typography variant="body2">
                {JSON.stringify(container.livenessProbe || 'None', null, 2)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  // Deployment Details
  const renderDeploymentDialogContent = () => {
    if (!selectedDeployment) return null;

    const { metadata, status, spec } = selectedDeployment;

    // Fetch associated pods
    const associatedPods = resourceData.pods.filter(
      (pod: Pod) =>
        pod.metadata.namespace === metadata.namespace &&
        pod.metadata.labels['app'] === metadata.labels['app']
    );
    const runningPods = associatedPods.filter(pod => pod.status.phase === 'Running').length;
    const totalPods = associatedPods.length;

    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          General Info
        </Typography>
        <Typography>
          <strong>Name:</strong> {metadata.name}
        </Typography>
        <Typography>
          <strong>Namespace:</strong> {metadata.namespace}
        </Typography>
        {renderLabels(metadata.labels)}
        <Typography>
          <strong>Replicas:</strong> {status.availableReplicas} / {spec.replicas}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Pods Status: {runningPods} / {totalPods} Running
        </Typography>
        <Typography>
          <strong>Strategy:</strong> {spec.strategy.type}
        </Typography>
        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Pods
        </Typography>
        <List>
          {associatedPods.map((pod: Pod) => (
            <ListItem
              button
              key={pod.metadata.name}
              onClick={() => handleOpenPodDialog(pod)}
              className={classes.listItem}
            >
              <ListItemText
                primary={pod.metadata.name}
                secondary={`Status: ${pod.status.phase}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  // Service Details
  const renderServiceDialogContent = () => {
    if (!selectedService) return null;

    const { metadata, spec, status } = selectedService;

    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          General Info
        </Typography>
        <Typography>
          <strong>Name:</strong> {metadata.name}
        </Typography>
        <Typography>
          <strong>Namespace:</strong> {metadata.namespace}
        </Typography>
        {renderLabels(metadata.labels)}
        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Connections
        </Typography>
        <Typography>
          <strong>Cluster IP:</strong> {spec.clusterIP}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Ports:</strong>
        </Typography>
        {spec.ports && spec.ports.length > 0 ? (
          <TableContainer component={Card} elevation={0} className={classes.tableContainer}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Port</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Protocol</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Target Port</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spec.ports.map((port: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{port.name || 'N/A'}</TableCell>
                    <TableCell>{port.port}</TableCell>
                    <TableCell>{port.protocol}</TableCell>
                    <TableCell>{port.targetPort}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2">No ports defined.</Typography>
        )}
        <Typography>
          <strong>Families:</strong> {spec.ipFamilies ? spec.ipFamilies.join(', ') : 'N/A'}
        </Typography>
        <Typography>
          <strong>Policies:</strong> {spec.externalTrafficPolicy || 'Cluster'}
        </Typography>
        <Typography>
          <strong>Endpoints:</strong>
        </Typography>
        <Box className={classes.preformatted}>
          <Typography variant="body2">
            {status.loadBalancer ? JSON.stringify(status.loadBalancer, null, 2) : 'N/A'}
          </Typography>
        </Box>
      </Box>
    );
  };

  // Ingress Details
  const renderIngressDialogContent = () => {
    if (!selectedIngress) return null;

    const { metadata, spec, status } = selectedIngress;

    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          General Info
        </Typography>
        <Typography>
          <strong>Name:</strong> {metadata.name}
        </Typography>
        <Typography>
          <strong>Namespace:</strong> {metadata.namespace}
        </Typography>
        {renderLabels(metadata.labels)}
        {renderLabels(metadata.annotations)}

        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Spec
        </Typography>
        <Typography>
          <strong>Ingress Class Name:</strong> {spec.ingressClassName || 'N/A'}
        </Typography>

        <Typography variant="subtitle1">
          <strong>Rules:</strong>
        </Typography>
        {spec.rules && spec.rules.length > 0 ? (
          spec.rules.map((rule: any, index: number) => (
            <Box key={index} mb={2}>
              <Typography>
                <strong>Host:</strong> {rule.host || 'N/A'}
              </Typography>
              <Typography variant="subtitle2">
                <strong>HTTP Paths:</strong>
              </Typography>
              {rule.http && rule.http.paths.length > 0 ? (
                <TableContainer
                  component={Card}
                  elevation={0}
                  className={classes.tableContainer}
                >
                  <Table size="small" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Path</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Backend Service</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rule.http.paths.map((path: any, idx: number) => (
                        <TableRow key={idx}>
                          <TableCell>{path.path || 'N/A'}</TableCell>
                          <TableCell>
                            {path.backend.service.name} : {path.backend.service.port.number}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body2">No HTTP paths defined.</Typography>
              )}
            </Box>
          ))
        ) : (
          <Typography variant="body2">No rules defined.</Typography>
        )}

        <Typography variant="subtitle1">
          <strong>TLS:</strong>
        </Typography>
        {spec.tls && spec.tls.length > 0 ? (
          spec.tls.map((tlsItem: any, idx: number) => (
            <Box key={idx} mb={2}>
              <Typography>
                <strong>Secret Name:</strong> {tlsItem.secretName || 'N/A'}
              </Typography>
              <Typography variant="subtitle2">
                <strong>Hosts:</strong>
              </Typography>
              <List dense>
                {tlsItem.hosts.map((host: string, hostIdx: number) => (
                  <ListItem key={hostIdx}>
                    <ListItemText primary={host} />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))
        ) : (
          <Typography variant="body2">No TLS configurations.</Typography>
        )}

        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Status
        </Typography>
        <Typography>
          <strong>Load Balancer:</strong>
        </Typography>
        {status.loadBalancer && Object.keys(status.loadBalancer).length > 0 ? (
          <Box className={classes.preformatted}>
            <Typography variant="body2">
              {JSON.stringify(status.loadBalancer, null, 2)}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2">No load balancer information.</Typography>
        )}
      </Box>
    );
  };

  // **Job Details**
  const renderJobDialogContent = () => {
    if (!selectedJob) return null;
    const { metadata, spec, status } = selectedJob;

    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          General Info
        </Typography>
        <Typography>
          <strong>Name:</strong> {metadata.name}
        </Typography>
        <Typography>
          <strong>Namespace:</strong> {metadata.namespace}
        </Typography>
        {renderLabels(metadata.labels)}
        {renderLabels(metadata.annotations)}

        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Spec
        </Typography>
        <Typography>
          <strong>Parallelism:</strong> {spec.parallelism ?? 'N/A'}
        </Typography>
        <Typography>
          <strong>Completions:</strong> {spec.completions ?? 'N/A'}
        </Typography>
        <Typography>
          <strong>Backoff Limit:</strong> {spec.backoffLimit ?? 'N/A'}
        </Typography>
        {spec.template?.spec?.containers?.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Containers:
            </Typography>
            {spec.template.spec.containers.map((container: any, idx: number) => (
              <Box key={idx} mb={1}>
                <Typography>
                  <strong>Name:</strong> {container.name}
                </Typography>
                <Typography>
                  <strong>Image:</strong> {container.image}
                </Typography>
                {container.command && (
                  <Box mt={1}>
                    <Typography variant="subtitle2">Command:</Typography>
                    <Box className={classes.preformatted}>
                      <Typography variant="body2">
                        {JSON.stringify(container.command, null, 2)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}

        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Status
        </Typography>
        <Typography>
          <strong>Start Time:</strong>{' '}
          {status?.startTime ? new Date(status.startTime).toLocaleString() : 'N/A'}
        </Typography>
        <Typography>
          <strong>Completion Time:</strong>{' '}
          {status?.completionTime ? new Date(status.completionTime).toLocaleString() : 'N/A'}
        </Typography>
        <Typography>
          <strong>Succeeded:</strong> {status?.succeeded ?? 0}
        </Typography>
        {status?.conditions && status.conditions.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle2">Conditions:</Typography>
            <List dense>
              {status.conditions.map((cond: any, idx: number) => (
                <React.Fragment key={idx}>
                  <ListItem>
                    <ListItemText
                      primary={`${cond.type} - ${cond.status}`}
                      secondary={`Last Transition: ${
                        cond.lastTransitionTime
                          ? new Date(cond.lastTransitionTime).toLocaleString()
                          : 'N/A'
                      }`}
                    />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          </Box>
        )}
      </Box>
    );
  };

  // **CronJob Details**
  const renderCronJobDialogContent = () => {
    if (!selectedCronJob) return null;
    const { metadata, spec, status } = selectedCronJob;

    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          General Info
        </Typography>
        <Typography>
          <strong>Name:</strong> {metadata.name}
        </Typography>
        <Typography>
          <strong>Namespace:</strong> {metadata.namespace}
        </Typography>
        {renderLabels(metadata.labels)}
        {renderLabels(metadata.annotations)}

        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Spec
        </Typography>
        <Typography>
          <strong>Schedule:</strong> {spec.schedule}
        </Typography>
        <Typography>
          <strong>Concurrency Policy:</strong> {spec.concurrencyPolicy || 'N/A'}
        </Typography>
        <Typography>
          <strong>Suspend:</strong> {String(spec.suspend ?? false)}
        </Typography>
        <Typography>
          <strong>Successful Jobs History Limit:</strong>{' '}
          {spec.successfulJobsHistoryLimit ?? 'N/A'}
        </Typography>
        <Typography>
          <strong>Failed Jobs History Limit:</strong>{' '}
          {spec.failedJobsHistoryLimit ?? 'N/A'}
        </Typography>
        {spec.jobTemplate?.spec?.template?.spec?.containers?.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Containers:
            </Typography>
            {spec.jobTemplate.spec.template.spec.containers.map((container: any, idx: number) => (
              <Box key={idx} mb={1}>
                <Typography>
                  <strong>Name:</strong> {container.name}
                </Typography>
                <Typography>
                  <strong>Image:</strong> {container.image}
                </Typography>
                {container.command && (
                  <Box mt={1}>
                    <Typography variant="subtitle2">Command:</Typography>
                    <Box className={classes.preformatted}>
                      <Typography variant="body2">
                        {JSON.stringify(container.command, null, 2)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}

        <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
          Status
        </Typography>
        <Typography>
          <strong>Last Schedule Time:</strong>{' '}
          {status?.lastScheduleTime ? new Date(status.lastScheduleTime).toLocaleString() : 'N/A'}
        </Typography>
        <Typography>
          <strong>Last Successful Time:</strong>{' '}
          {status?.lastSuccessfulTime
            ? new Date(status.lastSuccessfulTime).toLocaleString()
            : 'N/A'}
        </Typography>
      </Box>
    );
  };

  // Handle Cluster Selection Change
  const handleClusterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setSelectedCluster(value);
    setSelectedNamespace('');
  };

  // Handle Namespace Selection Change
  const handleNamespaceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setSelectedNamespace(value);
  };

  // Handle Resource Type Change
  const handleResourceTypeChange = (resourceType: string) => {
    setSelectedResourceType(resourceType);
    setSelectedNamespace('');
  };

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" px={2}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  if (clusters.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" px={2}>
        <Typography variant="h6">
          No clusters found. Please add a cluster in the Admin section.
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      {/* SIDEBAR */}
      <div className={classes.sidebar}>
        <Typography variant="h6" gutterBottom>
          Clusters
        </Typography>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>Cluster</InputLabel>
          <Select value={selectedCluster} onChange={handleClusterChange} label="Cluster">
            {clusters.map(cluster => (
              <MenuItem key={cluster.id} value={cluster.name}>
                {cluster.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedResourceType !== 'overview' &&
          resourceTypes.find(r => r.key === selectedResourceType)?.namespaceScoped && (
            <FormControl variant="outlined" size="small" className={classes.formControl}>
              <InputLabel>Namespace</InputLabel>
              <Select value={selectedNamespace} onChange={handleNamespaceChange} label="Namespace">
                <MenuItem value="">All</MenuItem>
                {namespaces.map(ns => (
                  <MenuItem key={ns} value={ns}>
                    {ns}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

        <Typography variant="h6" gutterBottom>
          Resources
        </Typography>
        <List>
          {resourceTypes.map(r => (
            <ListItem
              button
              key={r.key}
              selected={r.key === selectedResourceType}
              onClick={() => handleResourceTypeChange(r.key)}
            >
              <ListItemIcon>{r.icon}</ListItemIcon>
              <ListItemText primary={r.label} />
            </ListItem>
          ))}
        </List>
      </div>

      {/* MAIN AREA */}
      <div className={classes.mainArea}>
        {/* Header */}
        <Box className={classes.header}>
          <Typography variant="h5" className={classes.headerTitle}>
            {resourceTypes.find(r => r.key === selectedResourceType)?.label || 'Resources'}
          </Typography>
        </Box>

        {selectedResourceType === 'overview' && (
          <>
            {/* Nodes Display */}
            <Typography variant="h5" gutterBottom>
              Nodes
            </Typography>
            {loading ? (
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <Grid container spacing={3} alignItems="stretch">
                  {resourceData.nodes && resourceData.nodes.length > 0 ? (
                    resourceData.nodes.map(node => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={node.metadata.name}>
                        <div className={classes.fullHeightFirstDiv}>
                          <Card
                            className={classes.nodeCard}
                            onClick={() => handleOpenNodeDialog(node)}
                            elevation={3}
                          >
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                {node.metadata.name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                CPU: {node.status.capacity.cpu}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Memory: {node.status.capacity.memory}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Pods: {node.status.capacity.pods}
                              </Typography>
                            </CardContent>
                          </Card>
                        </div>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography className={classes.emptyState}>No nodes available.</Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
            <Typography variant="h5" gutterBottom>
              Deployment
            </Typography>

            {/* Charts */}
            <Box>
              <Grid container spacing={4} alignItems="stretch">
                {/* Pods Running Chart */}
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.fullHeightFirstDiv}>
                    <Card
                      className={classes.chartBox}
                      onClick={() => handleResourceTypeChange('pods')}
                    >
                      <CardContent>
                        <Typography align="center" variant="h6" gutterBottom>
                          Pods
                        </Typography>
                        <Box style={{ position: 'relative', width: '100%', height: '60%' }}>
                          <Doughnut
                            data={{
                              labels: ['Running', 'Not Running'],
                              datasets: [
                                {
                                  data: [
                                    chartData.pods.running,
                                    chartData.pods.total - chartData.pods.running,
                                  ],
                                  backgroundColor: ['#4caf50', '#f44336'],
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            options={{
                              maintainAspectRatio: false,
                              cutout: '70%',
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                tooltip: {
                                  enabled: true,
                                },
                              },
                            }}
                          />
                        </Box>
                        <Typography align="center" style={{ marginTop: '8px' }}>
                          {chartData.pods.running} / {chartData.pods.total}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>

                {/* Deployments Running Chart */}
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.fullHeightFirstDiv}>
                    <Card
                      className={classes.chartBox}
                      onClick={() => handleResourceTypeChange('deployments')}
                    >
                      <CardContent>
                        <Typography align="center" variant="h6" gutterBottom>
                          Deployments
                        </Typography>
                        <Box style={{ position: 'relative', width: '100%', height: '60%' }}>
                          <Doughnut
                            data={{
                              labels: ['Available', 'Not Available'],
                              datasets: [
                                {
                                  data: [
                                    chartData.deployments.running,
                                    chartData.deployments.total - chartData.deployments.running,
                                  ],
                                  backgroundColor: ['#2196f3', '#ff9800'],
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            options={{
                              maintainAspectRatio: false,
                              cutout: '70%',
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                tooltip: {
                                  enabled: true,
                                },
                              },
                            }}
                          />
                        </Box>
                        <Typography align="center" style={{ marginTop: '8px' }}>
                          {chartData.deployments.running} / {chartData.deployments.total}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {/* Resource Specific Display */}
        {selectedResourceType !== 'overview' && (
          <>
            {/* Loading State */}
            {loading ? (
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {/* Resource Cards */}
                {displayedResources.length > 0 ? (
                  <Grid container spacing={3} alignItems="stretch">
                    {displayedResources.map((resource: any) => {
                      const name = resource.metadata.name;
                      switch (selectedResourceType) {
                        case 'pods':
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
                              <div className={classes.fullHeightFirstDiv}>
                                <Card
                                  className={classes.resourceCard}
                                  onClick={() => handleOpenPodDialog(resource)}
                                  elevation={2}
                                >
                                  <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                      {name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Status:{' '}
                                      <Chip
                                        label={`${resource.status.phase}`}
                                        color={
                                          resource.status.phase === 'Running'
                                            ? 'primary'
                                            : resource.status.phase === 'Pending'
                                            ? 'default'
                                            : resource.status.phase === 'Failed'
                                            ? 'secondary'
                                            : 'default'
                                        }
                                        size="small"
                                        style={{ textTransform: 'capitalize' }}
                                      />
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Containers:
                                    </Typography>
                                    <Box display="flex" flexWrap="wrap" mt={1}>
                                      {resource.spec.containers.map(
                                        (container: any, idx: number) => (
                                          <Box
                                            key={idx}
                                            display="flex"
                                            alignItems="center"
                                            mr={1}
                                            mb={1}
                                          >
                                            <ContainerStatus
                                              status={
                                                resource.status.containerStatuses?.[idx]?.state
                                                  ?.running
                                                  ? 'Running'
                                                  : resource.status.containerStatuses?.[idx]?.state
                                                      ?.waiting
                                                  ? 'Pending'
                                                  : resource.status.containerStatuses?.[idx]?.state
                                                        ?.terminated?.reason || 'Unknown'
                                              }
                                            />
                                            <Typography variant="body2">
                                              {container.name}
                                            </Typography>
                                          </Box>
                                        )
                                      )}
                                    </Box>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                          );
                        case 'deployments':
                          const associatedPods = resourceData.pods.filter(
                            (pod: Pod) =>
                              pod.metadata.namespace === resource.metadata.namespace &&
                              pod.metadata.labels['app'] === resource.metadata.labels['app']
                          );
                          const runningPods = associatedPods.filter(
                            pod => pod.status.phase === 'Running'
                          ).length;
                          const totalPods = associatedPods.length;
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
                              <div className={classes.fullHeightFirstDiv}>
                                <Card
                                  className={classes.resourceCard}
                                  onClick={() => handleOpenDeploymentDialog(resource)}
                                  elevation={2}
                                >
                                  <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                      {name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Replicas: {resource.status.availableReplicas} /{' '}
                                      {resource.spec.replicas}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Pods Status: {runningPods} / {totalPods} Running
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                          );
                        case 'services':
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
                              <div className={classes.fullHeightFirstDiv}>
                                <Card
                                  className={classes.resourceCard}
                                  onClick={() => handleOpenServiceDialog(resource)}
                                  elevation={2}
                                >
                                  <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                      {name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Type: {resource.spec.type}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Cluster IP: {resource.spec.clusterIP}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                          );
                        case 'jobs':
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
                              <Card
                                className={classes.resourceCard}
                                onClick={() => handleOpenJobDialog(resource)}
                                elevation={2}
                              >
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    {name}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Status:{' '}
                                    {
                                      // If a job is complete, there might be a "Complete" condition
                                      resource.status?.conditions?.[0]?.type ?? 'N/A'
                                    }
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Succeeded: {resource.status?.succeeded ?? 0}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        case 'cronjobs':
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
                              <Card
                                className={classes.resourceCard}
                                onClick={() => handleOpenCronJobDialog(resource)}
                                elevation={2}
                              >
                                <CardContent>
                                  <Typography variant="h6" gutterBottom>
                                    {name}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Schedule: {resource.spec.schedule}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Last Scheduled:{' '}
                                    {resource.status.lastScheduleTime ||
                                      'N/A'}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        case 'ingresses':
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
                              <div className={classes.fullHeightFirstDiv}>
                                <Card
                                  className={classes.resourceCard}
                                  onClick={() => handleOpenIngressDialog(resource)}
                                  elevation={2}
                                >
                                  <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                      {name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      Ingress Class:{' '}
                                      {resource.spec.ingressClassName || 'N/A'}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      TLS: {resource.spec.tls ? resource.spec.tls.length : 0}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                          );
                        default:
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
                              <div className={classes.fullHeightFirstDiv}>
                                <Card className={classes.resourceCard} elevation={2}>
                                  <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                      {name}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                          );
                      }
                    })}
                  </Grid>
                ) : (
                  <Typography className={classes.emptyState}>
                    No{' '}
                    {resourceTypes
                      .find(r => r.key === selectedResourceType)
                      ?.label.toLowerCase()}{' '}
                    available.
                  </Typography>
                )}
              </>
            )}
          </>
        )}

        {/* Node Details Dialog */}
        <Dialog open={!!selectedNode} onClose={handleCloseNodeDialog} maxWidth="md" fullWidth>
          <DialogTitle disableTypography className={classes.dialogHeader}>
            <Typography variant="h6">{selectedNode?.metadata.name}</Typography>
            <IconButton onClick={handleCloseNodeDialog} aria-label="Close node details dialog">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{renderNodeDialogContent()}</DialogContent>
        </Dialog>

        {/* Pod Details Dialog */}
        <Dialog open={!!selectedPod} onClose={handleClosePodDialog} maxWidth="md" fullWidth>
          <DialogTitle disableTypography className={classes.dialogHeader}>
            <Typography variant="h6">Pod: {selectedPod?.metadata.name}</Typography>
            <IconButton onClick={handleClosePodDialog} aria-label="Close pod details dialog">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{renderPodDialogContent()}</DialogContent>
        </Dialog>

        {/* Deployment Details Dialog */}
        <Dialog
          open={!!selectedDeployment}
          onClose={handleCloseDeploymentDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle disableTypography className={classes.dialogHeader}>
            <Typography variant="h6">
              Deployment: {selectedDeployment?.metadata.name}
            </Typography>
            <IconButton
              onClick={handleCloseDeploymentDialog}
              aria-label="Close deployment details dialog"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{renderDeploymentDialogContent()}</DialogContent>
        </Dialog>

        {/* Service Details Dialog */}
        <Dialog
          open={!!selectedService}
          onClose={handleCloseServiceDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle disableTypography className={classes.dialogHeader}>
            <Typography variant="h6">
              Service: {selectedService?.metadata.name}
            </Typography>
            <IconButton
              onClick={handleCloseServiceDialog}
              aria-label="Close service details dialog"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{renderServiceDialogContent()}</DialogContent>
        </Dialog>

        {/* Ingress Details Dialog */}
        <Dialog
          open={!!selectedIngress}
          onClose={handleCloseIngressDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle disableTypography className={classes.dialogHeader}>
            <Typography variant="h6">
              Ingress: {selectedIngress?.metadata.name}
            </Typography>
            <IconButton
              onClick={handleCloseIngressDialog}
              aria-label="Close ingress details dialog"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{renderIngressDialogContent()}</DialogContent>
        </Dialog>

        {/* Job Details Dialog */}
        <Dialog open={!!selectedJob} onClose={handleCloseJobDialog} maxWidth="md" fullWidth>
          <DialogTitle disableTypography className={classes.dialogHeader}>
            <Typography variant="h6">Job: {selectedJob?.metadata.name}</Typography>
            <IconButton onClick={handleCloseJobDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{renderJobDialogContent()}</DialogContent>
        </Dialog>

        {/* CronJob Details Dialog */}
        <Dialog open={!!selectedCronJob} onClose={handleCloseCronJobDialog} maxWidth="md" fullWidth>
          <DialogTitle disableTypography className={classes.dialogHeader}>
            <Typography variant="h6">
              CronJob: {selectedCronJob?.metadata.name}
            </Typography>
            <IconButton onClick={handleCloseCronJobDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{renderCronJobDialogContent()}</DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
