// src/api/K8sResourcesApi.ts

import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

/**
 * API reference for Kubernetes Resources.
 */
export const k8sResourcesApiRef = createApiRef<K8sResourcesApi>({
  id: 'plugin.k8s-resources.service',
});

/**
 * Represents a Kubernetes cluster record.
 */
export interface ClusterRecord {
  id: string;
  name: string;
  kubeconfig: string;
  created_at: string;
}

/**
 * Represents the response structure for Kubernetes resources.
 */
export interface ClusterResources {
  namespaces: string[];
  nodes: Node[];
  pods: Pod[];
  deployments: Deployment[];
  services: Service[];
  ingresses: Ingress[];
  jobs: Job[];
  cronjobs: CronJob[];
  // Add more resources as needed
}

/**
 * Represents a Kubernetes Node.
 */
export interface Node {
  metadata: {
    name: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
  };
  status: {
    capacity: Record<string, string>;
    addresses: Array<{ type: string; address: string }>;
    nodeInfo: {
      osImage: string;
      kernelVersion: string;
      kubeletVersion: string;
    };
  };
}

/**
 * Represents a Kubernetes Pod.
 */
export interface Pod {
  metadata: {
    name: string;
    namespace: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
    nodeName?: string;
  };
  spec: {
    nodeName?: string;
    containers: Array<{
      name: string;
      image: string;
      ports?: Array<{ containerPort: number }>;
      livenessProbe?: any; // Define more specific types as needed
    }>;
  };
  status: {
    phase: string;
    podIP: string;
    qosClass: string;
    containerStatuses?: Array<{
      state?: {
        running?: { startedAt: string };
        waiting?: { reason: string };
        terminated?: { reason: string };
      };
    }>;
  };
}

/**
 * Represents a Kubernetes Deployment.
 */
export interface Deployment {
  metadata: {
    name: string;
    namespace: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
  };
  spec: {
    replicas: number;
    strategy: {
      type: string;
    };
    template: {
      metadata: {
        labels?: Record<string, string>;
        annotations?: Record<string, string>;
      };
      spec: {
        restartPolicy: string;
        containers: Array<{
          name: string;
          image: string;
          ports?: Array<{ containerPort: number }>;
        }>;
      };
    };
  };
  status: {
    availableReplicas: number;
    replicas: number;
  };
}

/**
 * Represents a Kubernetes Service.
 */
export interface Service {
  metadata: {
    name: string;
    namespace: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
  };
  spec: {
    clusterIP: string;
    type: string;
    ports?: Array<{
      name?: string;
      port: number;
      protocol: string;
      targetPort: number;
    }>;
    ipFamilies?: string[];
    externalTrafficPolicy?: string;
  };
  status: {
    loadBalancer?: any; // Define more specific types as needed
  };
}

/**
 * Represents a Kubernetes Ingress.
 */
export interface Ingress {
  metadata: {
    name: string;
    namespace: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
  };
  spec: {
    ingressClassName?: string;
    rules?: Array<{
      host?: string;
      http?: {
        paths: Array<{
          path?: string;
          backend: {
            service: {
              name: string;
              port: {
                number: number;
              };
            };
          };
        }>;
      };
    }>;
    tls?: Array<{
      hosts?: string[];
      secretName?: string;
    }>;
  };
  status: {
    loadBalancer?: any; // Define more specific types as needed
  };
}

/**
 * Represents a Kubernetes Job.
 */
export interface Job {
  metadata: {
    name: string;
    namespace: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
  };
  spec: {
    parallelism?: number;
    completions?: number;
    backoffLimit?: number;
    template: {
      metadata: {
        labels?: Record<string, string>;
        annotations?: Record<string, string>;
      };
      spec: {
        restartPolicy: string;
        containers: Array<{
          name: string;
          image: string;
          ports?: Array<{ containerPort: number }>;
        }>;
      };
    };
  };
  status?: {
    active?: number;
    succeeded?: number;
    failed?: number;
    startTime?: string;
    completionTime?: string;
  };
}

/**
 * Represents a Kubernetes CronJob.
 */
export interface CronJob {
  metadata: {
    name: string;
    namespace: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
  };
  spec: {
    schedule: string;
    concurrencyPolicy?: string;
    suspend?: boolean;
    successfulJobsHistoryLimit?: number;
    failedJobsHistoryLimit?: number;
    jobTemplate: {
      metadata: {
        labels?: Record<string, string>;
        annotations?: Record<string, string>;
      };
      spec: {
        template: {
          metadata: {
            labels?: Record<string, string>;
            annotations?: Record<string, string>;
          };
          spec: {
            restartPolicy: string;
            containers: Array<{
              name: string;
              image: string;
              ports?: Array<{ containerPort: number }>;
            }>;
          };
        };
      };
    };
  };
  status?: {
    lastScheduledTime?: string;
    active?: Array<{ name: string }>;
    lastSuccessfulTime?: string;
    lastFailedTime?: string;
  };
}

/**
 * Options for starting a port-forward session.
 */
export interface StartPortForwardOptions {
  clusterName: string;
  namespace: string;
  resourceType: string; // e.g., "pods", "services"
  resourceName: string;
  targetPort: number;
  localPort: number;
}

/**
 * Interface defining the Kubernetes Resources API methods.
 */
export interface K8sResourcesApi {
  listClusters(): Promise<ClusterRecord[]>;
  addCluster(name: string, kubeconfigFile: File): Promise<void>;
  removeCluster(name: string): Promise<void>;
  downloadClusterKubeconfig(name: string): Promise<void>;

  getClusterResources(clusterName: string): Promise<ClusterResources>;
  deletePod(clusterName: string, namespace: string, podName: string): Promise<void>;
  addSecret(
    clusterName: string,
    namespace: string,
    secretName: string,
    data: Record<string, string>,
  ): Promise<void>;
  startPortForward(opts: StartPortForwardOptions): Promise<{ pfId: string }>;
  stopPortForward(clusterName: string, namespace: string, pfId: string): Promise<void>;

  // Methods for Jobs
  listJobs(clusterName: string, namespace: string): Promise<Job[]>;
  createJob(clusterName: string, namespace: string, job: Job): Promise<void>;
  deleteJob(clusterName: string, namespace: string, jobName: string): Promise<void>;

  // Methods for CronJobs
  listCronJobs(clusterName: string, namespace: string): Promise<CronJob[]>;
  createCronJob(clusterName: string, namespace: string, cronJob: CronJob): Promise<void>;
  deleteCronJob(clusterName: string, namespace: string, cronJobName: string): Promise<void>;
}

/**
 * Client implementation of the Kubernetes Resources API.
 */
export class K8sResourcesClient implements K8sResourcesApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi,
  ) {}

  /**
   * Helper method to centralize fetch logic with authentication and error handling.
   *
   * @param endpoint - The API endpoint to call.
   * @param options - Fetch options.
   * @returns The fetch response.
   * @throws Error if the API request fails.
   */
  private async fetchFromApi(endpoint: string, options: RequestInit = {}): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('k8s-resources');
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = {
      ...options.headers,
      Authorization: token ? `Bearer ${token}` : '',
    };

    const resp = await fetch(`${baseUrl}${endpoint}`, { ...options, headers });
    if (!resp.ok) {
      const errorText = await resp.text();
      throw new Error(`API request failed: ${resp.status} - ${errorText}`);
    }
    return resp;
  }

  /**
   * Retrieves the list of Kubernetes clusters.
   *
   * @returns An array of ClusterRecord objects.
   */
  async listClusters(): Promise<ClusterRecord[]> {
    const resp = await this.fetchFromApi('/clusters');
    return resp.json();
  }

  /**
   * Adds a new Kubernetes cluster.
   *
   * @param name - The name of the cluster.
   * @param kubeconfigFile - The kubeconfig file for the cluster.
   */
  async addCluster(name: string, kubeconfigFile: File): Promise<void> {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('kubeconfigFile', kubeconfigFile);

    await this.fetchFromApi('/clusters', {
      method: 'POST',
      body: formData,
    });
  }

  /**
   * Removes an existing Kubernetes cluster by name.
   *
   * @param name - The name of the cluster to remove.
   */
  async removeCluster(name: string): Promise<void> {
    await this.fetchFromApi(`/clusters/${encodeURIComponent(name)}`, {
      method: 'DELETE',
    });
  }

  /**
   * Downloads the kubeconfig file for a specified cluster.
   *
   * @param name - The name of the cluster.
   */
  async downloadClusterKubeconfig(name: string): Promise<void> {
    const resp = await this.fetchFromApi(`/clusters/${encodeURIComponent(name)}/kubeconfig`, {
      method: 'GET',
    });

    const fileBlob = await resp.blob();
    const blobUrl = URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${name}-kubeconfig.yaml`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(blobUrl);
  }

  /**
   * Retrieves resources for a specific Kubernetes cluster.
   *
   * @param clusterName - The name of the cluster.
   * @returns The cluster resources.
   */
  async getClusterResources(clusterName: string): Promise<ClusterResources> {
    const resp = await this.fetchFromApi(`/clusters/${encodeURIComponent(clusterName)}/resources`);
    return resp.json();
  }

  /**
   * Deletes a pod from a specified cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace of the pod.
   * @param podName - The name of the pod to delete.
   */
  async deletePod(clusterName: string, namespace: string, podName: string): Promise<void> {
    await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/pods/${encodeURIComponent(podName)}`,
      {
        method: 'DELETE',
      },
    );
  }

  /**
   * Adds a secret to a specified cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace to add the secret to.
   * @param secretName - The name of the secret.
   * @param data - Key-value pairs for the secret data (base64-encoded).
   */
  async addSecret(
    clusterName: string,
    namespace: string,
    secretName: string,
    data: Record<string, string>,
  ): Promise<void> {
    // Validate that all data values are base64-encoded
    for (const [key, value] of Object.entries(data)) {
      if (!this.isBase64(value)) {
        throw new Error(`Value for key "${key}" is not valid base64.`);
      }
    }

    const secretBody = {
      metadata: {
        name: secretName,
      },
      data, // Must be base64-encoded
    };

    await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/secrets`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(secretBody),
      },
    );
  }

  /**
   * Starts a port-forward session.
   *
   * @param opts - Options for port-forwarding.
   * @returns An object containing the port-forward ID.
   */
  async startPortForward(opts: StartPortForwardOptions): Promise<{ pfId: string }> {
    const resp = await this.fetchFromApi(
      `/clusters/${encodeURIComponent(opts.clusterName)}/namespaces/${encodeURIComponent(opts.namespace)}/port-forward`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(opts),
      },
    );
    return resp.json();
  }

  /**
   * Stops a previously started port-forward session.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace of the port-forward session.
   * @param pfId - The ID of the port-forward session to stop.
   */
  async stopPortForward(
    clusterName: string,
    namespace: string,
    pfId: string,
  ): Promise<void> {
    await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/port-forward/${encodeURIComponent(pfId)}`,
      {
        method: 'DELETE',
      },
    );
  }

  // **Job Methods**

  /**
   * Retrieves the list of Jobs in a specific cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace to list Jobs from.
   * @returns An array of Job objects.
   */
  async listJobs(clusterName: string, namespace: string): Promise<Job[]> {
    const resp = await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/jobs`,
    );
    return resp.json();
  }

  /**
   * Creates a new Job in a specific cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace to create the Job in.
   * @param job - The Job object to create.
   */
  async createJob(clusterName: string, namespace: string, job: Job): Promise<void> {
    await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/jobs`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(job),
      },
    );
  }

  /**
   * Deletes a Job from a specific cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace of the Job.
   * @param jobName - The name of the Job to delete.
   */
  async deleteJob(clusterName: string, namespace: string, jobName: string): Promise<void> {
    await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/jobs/${encodeURIComponent(jobName)}`,
      {
        method: 'DELETE',
      },
    );
  }

  // **CronJob Methods**

  /**
   * Retrieves the list of CronJobs in a specific cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace to list CronJobs from.
   * @returns An array of CronJob objects.
   */
  async listCronJobs(clusterName: string, namespace: string): Promise<CronJob[]> {
    const resp = await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/cronjobs`,
    );
    return resp.json();
  }

  /**
   * Creates a new CronJob in a specific cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace to create the CronJob in.
   * @param cronJob - The CronJob object to create.
   */
  async createCronJob(clusterName: string, namespace: string, cronJob: CronJob): Promise<void> {
    await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/cronjobs`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cronJob),
      },
    );
  }

  /**
   * Deletes a CronJob from a specific cluster and namespace.
   *
   * @param clusterName - The name of the cluster.
   * @param namespace - The namespace of the CronJob.
   * @param cronJobName - The name of the CronJob to delete.
   */
  async deleteCronJob(clusterName: string, namespace: string, cronJobName: string): Promise<void> {
    await this.fetchFromApi(
      `/clusters/${encodeURIComponent(clusterName)}/namespaces/${encodeURIComponent(namespace)}/cronjobs/${encodeURIComponent(cronJobName)}`,
      {
        method: 'DELETE',
      },
    );
  }

  /**
   * Utility method to check if a string is valid base64.
   *
   * @param str - The string to validate.
   * @returns True if the string is valid base64, false otherwise.
   */
  private isBase64(str: string): boolean {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
}