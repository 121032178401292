import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  statusSquare: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  green: {
    backgroundColor: '#4caf50',
  },
  yellow: {
    backgroundColor: '#ffeb3b',
  },
  gray: {
    backgroundColor: '#9e9e9e',
  },
  red: {
    backgroundColor: '#f44336',
  },
}));

interface ContainerStatusProps {
  status: string;
}

export const ContainerStatus: React.FC<ContainerStatusProps> = ({ status }) => {
  const classes = useStyles();

  let colorClass;
  let tooltipText;

  switch (status.toLowerCase()) {
    case 'running':
      colorClass = classes.green;
      tooltipText = 'Running';
      break;
    case 'pending':
      colorClass = classes.yellow;
      tooltipText = 'Pending';
      break;
    case 'unknown':
      colorClass = classes.gray;
      tooltipText = 'Unknown';
      break;
    case 'failed':
      colorClass = classes.red;
      tooltipText = 'Failed';
      break;
    default:
      colorClass = classes.gray;
      tooltipText = status;
  }

  return (
    <Tooltip title={tooltipText}>
      <span className={`${classes.statusSquare} ${colorClass}`}></span>
    </Tooltip>
  );
};
