import React from 'react';
import { Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { Content, PageWithHeader, SupportButton } from '@backstage/core-components';
import { GitLabGroupOverviewCard } from '@internal/backstage-plugin-user-managment';
import { K8sAdminClusterWidget } from '@internal/backstage-plugin-k8s';
import { useApi, identityApiRef } from '@backstage/core-plugin-api'; // Import the identity API
import { useAsync } from 'react-use';

const Users = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <GitLabGroupOverviewCard />
    </Grid>
  </Grid>
);

const K8s = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <K8sAdminClusterWidget />
    </Grid>
  </Grid>
);

const Overview = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Users />
    </Grid>
  </Grid>
);

const TabNavigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Tabs
      value={currentPath}
      aria-label="administration navigation tabs"
      variant="scrollable"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Overview" value="/administration" component={Link} to="/administration" />
      <Tab label="K8s" value="/administration/k8s" component={Link} to="/administration/k8s" />
    </Tabs>
  );
};

// Main Administration Page Component
export const AdministrationPage = () => {
  const identityApi = useApi(identityApiRef);

  // Fetch user's profile
  const { value: profile } = useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    return identity;
  }, []);

  // Return null if profile is not loaded
  if (!profile) {
    return null;
  }

  // Extract the username from userEntityRef, removing 'user:default/' prefix
  const username = profile.userEntityRef.split('/').pop(); // Extracts the part after 'user:default/'

  // Check if the user is a guest
  if (username.toLowerCase() === 'guest') {
    // Option 1: Display an access denied message
    return (
      <PageWithHeader themeId="library" title="Access Denied">
        <Content>
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Access Denied</h2>
            <p>You do not have permission to view this page.</p>
          </div>
        </Content>
      </PageWithHeader>
    );

    // Option 2: Redirect to another page (uncomment to use)
    // return <Navigate to="/access-denied" replace />;
  }

  // If not a guest, render the administration content
  return (
    <PageWithHeader themeId="library" title="Platform Administration">
      <Content>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <TabNavigation />
          <SupportButton>Project security page.</SupportButton>
        </div>
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/k8s" element={<K8s />} />
        </Routes>
      </Content>
    </PageWithHeader>
  );
};
