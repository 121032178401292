import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { k8sResourcesApiRef, ClusterRecord } from '../api/K8sResourcesApi';
import { Progress } from '@backstage/core-components';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp'; 

const useStyles = makeStyles(theme => ({
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  clusterCard: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    marginRight: theme.spacing(1),
  },
  cardActions: {
    display: 'flex',
    gap: theme.spacing(1), 
  },
}));

export const K8sAdminClusterWidget = () => {
  const classes = useStyles();
  const k8sResourcesApi = useApi(k8sResourcesApiRef);

  const [clusters, setClusters] = useState<ClusterRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Dialog state
  const [dialogOpen, setDialogOpen] = useState(false);

  // New cluster form
  const [clusterName, setClusterName] = useState('');
  const [kubeconfigFile, setKubeconfigFile] = useState<File | null>(null);

  // Load clusters
  const loadClusters = async () => {
    setLoading(true);
    setError(null);
    try {
      const list = await k8sResourcesApi.listClusters();
      setClusters(list);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadClusters();
  }, []);

  const handleOpenDialog = () => {
    setClusterName('');
    setKubeconfigFile(null);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAddCluster = async () => {
    if (!clusterName || !kubeconfigFile) {
      setError('Please provide cluster name and kubeconfig file');
      return;
    }
    setError(null);
    setLoading(true);
    try {
      await k8sResourcesApi.addCluster(clusterName, kubeconfigFile);
      await loadClusters();
      setDialogOpen(false);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCluster = async (name: string) => {
    if (!window.confirm(`Remove cluster "${name}"?`)) {
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await k8sResourcesApi.removeCluster(name);
      await loadClusters();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  // Download Kubeconfig
  const handleDownloadKubeconfig = async (name: string) => {
    try {
      await k8sResourcesApi.downloadClusterKubeconfig(name);
    } catch (err: any) {
      setError(`Failed to download kubeconfig: ${err.message}`);
    }
  };

  if (loading) {
    return <Progress />;
  }

  return (
    <div>
      <div className={classes.headerRow}>
        <Typography variant="h4">Kubernetes Clusters Administration</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
        >
          Add Cluster
        </Button>
      </div>

      {error && (
        <Typography color="error" style={{ marginBottom: 16 }}>
          {error}
        </Typography>
      )}

      <Grid container spacing={2}>
        {clusters.map(cluster => (
          <Grid item xs={12} md={6} lg={4} key={cluster.id}>
            <Card className={classes.clusterCard}>
              <CardContent>
                <div className={classes.cardHeader}>
                  <div>
                    <Typography variant="subtitle1" className={classes.cardTitle}>
                      Name: {cluster.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Created: {cluster.created_at}
                    </Typography>
                  </div>

                  <div className={classes.cardActions}>
                    <IconButton
                      color="default"
                      title="Download Kubeconfig"
                      onClick={() => handleDownloadKubeconfig(cluster.name)}
                    >
                      <GetAppIcon />
                    </IconButton>

                    <IconButton
                      color="secondary"
                      title="Remove Cluster"
                      onClick={() => handleRemoveCluster(cluster.name)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* DIALOG FOR ADDING NEW CLUSTER */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Cluster</DialogTitle>
        <DialogContent>
          <TextField
            label="Cluster Name"
            value={clusterName}
            onChange={e => setClusterName(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <input
            type="file"
            style={{ marginTop: 16 }}
            onChange={e => {
              if (e.target.files?.[0]) {
                setKubeconfigFile(e.target.files[0]);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={handleAddCluster}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
