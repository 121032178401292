import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, Divider, Grid, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumingComponentsCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
  
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState, GroupIcon, InfoCard } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  EntityRelation,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { CatalogApi, catalogApiRef, getEntityRelations } from '@backstage/plugin-catalog-react';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { isGitlabAvailable, EntityGitlabReleasesCard, EntityGitlabMergeRequestStatsCard, EntityGitlabLanguageCard, EntityGitlabMergeRequestsTable, EntityGitlabPipelinesTable, EntityGitlabReadmeCard } from '@immobiliarelabs/backstage-plugin-gitlab';
import { EntityGitlabPeopleCard } from '@immobiliarelabs/backstage-plugin-gitlab';
import { EntityGrafanaAlertsCard, EntityGrafanaDashboardsCard } from '@backstage-community/plugin-grafana';
import { EntityJiraDashboardContent, isJiraDashboardAvailable } from '@axis-backstage/plugin-jira-dashboard';
import { EntityHighlightsCard } from '@rsc-labs/backstage-highlights-plugin';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import '../../global.css';
import {GitLabAssignedIssuesComponent, GitLabJobsComponent, LatestBuildWidget} from '@internal/backstage-plugin-taskpage'
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { useNavigate } from 'react-router-dom';
import CodeIcon from '@material-ui/icons/Code';
import { UserLinksCard } from '@itsyndicate/backstage-plugin-user-links';
import { GitLabGroupOverviewCard } from '@internal/backstage-plugin-user-managment';
import { EntityKubernetesContent } from '@backstage/plugin-kubernetes';

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);
const LatestBuildContent = () => {
  const { entity } = useEntity();
  const ownedByRelations = entity?.relations?.filter(
    (r: EntityRelation) => r.type === RELATION_OWNED_BY
  ) || [];

  // Assuming only one owner, extract groupName directly
  const groupName = ownedByRelations.length > 0 ? ownedByRelations[0].targetRef.split('/')[1] : '';
  const projectName = entity.metadata.name;
  return (
   <LatestBuildWidget groupName={groupName} projectName={projectName} />
  )
}
const CicdContent = () => {
  const { entity } = useEntity();
  const ownedByRelations = entity?.relations?.filter(
    (r: EntityRelation) => r.type === RELATION_OWNED_BY
  ) || [];

  // Assuming only one owner, extract groupName directly
  const groupName = ownedByRelations.length > 0 ? ownedByRelations[0].targetRef.split('/')[1] : '';
  const projectName = entity.metadata.name;
  const identityApi = useApi(identityApiRef);

  // Fetch user's profile
  const { value: profile } = useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    return identity;
  }, []);

  // Return null if profile is not loaded
  if (!profile) {
    return null;
  }

  // Extract the username from userEntityRef, removing 'user:default/' prefix
  const username = profile.userEntityRef.split('/').pop(); // Extracts the part after 'user:default/'
  return (
    <EntitySwitch>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <EntityGitlabPipelinesTable />
          </Grid>
          <Grid item md={12}>
            <EntityGitlabMergeRequestsTable />
          </Grid>
          <Grid item md={12}>
            <GitLabJobsComponent groupName={groupName} projectName={projectName} />
          </Grid>
          <Grid item md={12}>
            <GitLabAssignedIssuesComponent user={username} projectName={projectName} />
          </Grid>
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <EmptyState
          title="No CI/CD available for this entity"
          missing="info"
          description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
          action={
            <Button
              variant="contained"
              color="primary"
              href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
            >
              Read more
            </Button>
          }
        />
      </EntitySwitch.Case>
    </EntitySwitch>
  );
};

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={12} xs={12}>
      <EntityHighlightsCard />
    </Grid>
    <Grid item sm={12} md={3} lg={3}>
      <div className='full-height-first-div'>
      <EntityAboutCard variant="gridItem" /></div>
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <Grid item sm={12} md={3} lg={3}>
        <div className='full-height-first-div'>
            <EntityGitlabPeopleCard /></div>
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
        <div className='full-height-first-div'>
            <EntityGitlabLanguageCard /></div>
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
        <div className='full-height-first-div'>
            <LatestBuildContent /></div>
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
        <div className='full-height-first-div'>
            <EntityGitlabMergeRequestStatsCard /></div>
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
        <div className='full-height-first-div'>
            <EntityGitlabReleasesCard /></div>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    {/* <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid> */}
  </Grid>
);
const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD" >
      <CicdContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent />
   </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      <CicdContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          {/* Grafana alert card start */}
          <EntityGrafanaDashboardsCard />
          {/* Grafana alert card end */}
        </Grid>
        <Grid item md={6}>
          {/* Grafana alert card start */}
          <EntityGrafanaAlertsCard />
          {/* Grafana alert card end */}
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserLinksCard/>
        </Grid>
        
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const GroupOwnedComponentsCard = () => {
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const [ownedComponents, setOwnedComponents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOwnedComponents = async () => {
      const response = await catalogApi.getEntities({
        filter: [
          { kind: 'component' }, // Filter for components
          { 'relations.ownedBy': `group:default/${entity.metadata.name}` }, // Filter for components owned by the group
        ],
        fields: ['metadata', 'kind', 'relations'],
      });

      const filteredComponents =
        response.items.filter((component) => {
          const ownedByRelations = component.relations?.filter(
            (r) => r.type === RELATION_OWNED_BY
          ) || [];
          return ownedByRelations.some(
            (relation) =>
              relation.targetRef === `group:default/${entity.metadata.name}`
          );
        });

      setOwnedComponents(filteredComponents);
    };

    fetchOwnedComponents();
  }, [catalogApi, entity]);

  // Function to handle click and navigate to the component page
  const handleClick = (kind, name) => {
    navigate(`/catalog/default/${kind}/${name}`);
  };
  const CardTitle = (props: { title: string }) => (
    <Box display="flex" alignItems="center">
      <IntegrationInstructionsIcon fontSize="inherit" />
      <Box ml={1}>{props.title}</Box>
    </Box>
  );
  const displayName = `Repositories in ${entity.metadata.name}`
  return (
    <InfoCard
      title={<CardTitle title={displayName}/>}
      variant='gridItem'
      subheader={undefined}
    >
      {ownedComponents.length > 0 ? (
        <List>
          {ownedComponents.map((component, index) => (
            <ListItem
              key={component.metadata.name}
              button
              onClick={() =>
                handleClick(component.kind, component.metadata.name)
              }
              style={{
                borderRadius: '8px',
                marginBottom: index === ownedComponents.length - 1 ? 0 : '10px', // No margin for the last item
                border: '1px solid #e0e0e0',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              }}
            >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: '#ff4081' }}>
                  <CodeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 'bold', color: '#3f51b5' }}
                  >
                    {component.metadata.name}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No owned components found.</Typography>
      )}
    </InfoCard>
  );
};
const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
        <div className='full-height-first-div'>
          <EntityGroupProfileCard variant="gridItem" /> </div>
        </Grid>
        <Grid item xs={12} md={6}>
        <div className='full-height-first-div'>
          <GroupOwnedComponentsCard /></div>
        </Grid>
        <Grid item xs={12} md={12}>
        <div className='full-height-first-div'>
          <EntityMembersListCard /></div>
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
